import { StatusBar } from 'expo-status-bar';
import  { useState, useEffect, useRef, useContext } from 'react'
import { StyleSheet, Text, View, Dimensions, TouchableOpacity, Pressable } from 'react-native';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import FadeInText from './common/FadeInText';


export default function HamburgerMenuExpanded(props) {



  return (
    <Pressable style={{height:props.height, width: props.width, backgroundColor: 'rgba(0, 0, 0, 0.8)', position: 'absolute', zIndex: 999, justifyContent: 'center'}}>
      <TouchableOpacity style={[styles.fadeText, {width: props.width}]} onPress={() => {props.switchTabs('HomeScreen', true); props.setImageOrVideo(false)}}> 
        <FadeInText duration={500} tab={props.tab} currentTab={'HomeScreen'}>
          <Text>Home</Text>
        </FadeInText>
      </TouchableOpacity>
      <TouchableOpacity style={[styles.fadeText, {width: props.width}]} onPress={() => {props.switchTabs('ServicesScreen', true); props.setImageOrVideo(false)}}> 
        <FadeInText duration={500} tab={props.tab} currentTab={'ServicesScreen'}>
          <Text>Services</Text>
        </FadeInText>
      </TouchableOpacity>
      <TouchableOpacity style={[styles.fadeText, {width: props.width}]} onPress={() => {props.switchTabs('AboutScreen', true); props.setImageOrVideo(false)}}> 
        <FadeInText duration={500} tab={props.tab} currentTab={'AboutScreen'}>
          <Text>About</Text>
        </FadeInText>
      </TouchableOpacity>
      <TouchableOpacity style={[styles.fadeText, {width: props.width}]} onPress={() => {props.switchTabs('ContactScreen', true); props.setImageOrVideo(false)}}> 
        <FadeInText duration={2500} tab={props.tab} currentTab={'ContactScreen'}>
          <Text>Contact</Text>
        </FadeInText>
      </TouchableOpacity>
    </Pressable>
  );
}

const styles = StyleSheet.create({
    fadeText: {
        height: 150,
        alignItems: 'center',
        justifyContent: 'center'
      }
});
