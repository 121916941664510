import { StyleSheet, Text, View, useWindowDimensions, Dimensions, Image, Animated, Easing, Linking, Alert, Platform} from 'react-native';
import { useFonts } from 'expo-font';
import { useEffect, useState, useRef, useCallback, useContext } from 'react';
import { TouchableOpacity } from 'react-native-web';
import WhatWeDoCard from '../common/WhatWeDoCard';
import Carousel from '../common/Carousel';
import { MainContext } from '../Contexts/MainContext';

const { height, width } = Dimensions.get('window');
// const fontScale = c

export default function Home(props) {
  const [FS, setFS] = useState(Math.min(window.innerWidth / 320, window.innerHeight / 640))
  const [FSS, setFSS] =  useState({ "width": Math.min(window.innerWidth), "height": Math.min(window.innerHeight)})
  const styles = makeStyles(FS); // pass in fontScale to the StyleSheet
  const smallScreen = FSS.width < 900;
  const [whoWeAreFade] = useState(new Animated.Value(0));
  const [homePageLogo] = useState(new Animated.Value(0));
  const [homePageText1] = useState(new Animated.Value(0));
  const [homePageText2] = useState(new Animated.Value(0));
  const [card1] = useState(new Animated.Value(0));
  const [card2] = useState(new Animated.Value(0));
  const [card3] = useState(new Animated.Value(0));
  const {scrollPosition, setScrollPosition, setTabIndex} = useContext(MainContext)

  useEffect(() => {
    animateHomePage()
    window.addEventListener('resize', changeTitleSize);
    return () => {
        window.removeEventListener('resize', changeTitleSize);
    }
  }, []);

  useEffect(() => {
    //console.log("SP", scrollPosition)
    if(scrollPosition >= 10) animateWhoWeAre()
    
  }, [scrollPosition]);

  function changeTitleSize(){
    setFS(Math.min(window.innerWidth / 320, window.innerHeight / 640))
    setFSS({ "width": Math.min(window.innerWidth), "height": Math.min(window.innerHeight)})
  }

  function weScrolled(){
    Animated.timing(whoWeAreFade, {
      toValue: 1,
      duration: 2500,
    }).start();
  }

  const callNumber = (phone) => {
    let phoneNumber = phone;
    if (Platform.OS === 'ios') { // <== Fix this condition
      phoneNumber = `telprompt:${phone}`;
    }
    else  {
      phoneNumber = `tel:${phone}`;
    }
    
    if (Platform.OS === 'web') {
      window.open(phoneNumber); // <== Pay attention here
    } else {
      Linking.canOpenURL(phoneNumber)
        .then(supported => {
          if (!supported) {
            Alert.alert('Phone number is not available');
          } else {
            return Linking.openURL(phoneNumber);
          }
        })
        .catch(err => console.log(err));
    }
  };

  const sleep = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  async function animateHomePage(){
    Animated.timing(homePageLogo, {
      toValue: 1,
      duration: 2500,
    }).start();
    await sleep(500)
    Animated.timing(homePageText1, {
      toValue: 1,
      duration: 2500,
    }).start();
    await sleep(500)
    Animated.timing(homePageText2, {
      toValue: 1,
      duration: 2500,
    }).start();
  }


  async function animateWhoWeAre(){
    // setEventListTriggered(true)
    Animated.timing(whoWeAreFade, {
      toValue: 1,
      duration: 2500,
    }).start();
    Animated.timing(card1, {
      toValue: 1,
      duration: 2500,
    }).start();
    await sleep(250)
    Animated.timing(card2, {
      toValue: 1,
      duration: 2500,
    }).start();
    await sleep(250)
    Animated.timing(card3, {
      toValue: 1,
      duration: 2500,
    }).start();
  }


  function switchTabs(index, screen){
    setTabIndex(index)
    props.switchTabs(screen)
  }

  

  return (
    <View style={{}}>
      <View style={{  }}>
        <Carousel/>
        <Animated.View style={{position: 'absolute', alignItems: 'center', justifyContent: 'center', height: FSS.height, opacity: homePageLogo, top: smallScreen && FS*50 }}>
          <Image
            source={require('../assets/Logo2.png')}
            style={{height: FS * 300, width:FS * 300, borderRadius: 20, opacity: .8}}
          />

          <View style={{flexDirection: !smallScreen ? 'row' : null, justifyContent: !smallScreen ? 'space-evenly' : null, width: FSS.width, top: smallScreen && FS*15}}>
            <Animated.View style={{justifyContent: 'center', alignItems: 'center', opacity: homePageText1}}>
              <Text style={{fontSize: FS * 20, color: '#f1ead7', paddingBottom: FS * 10, fontWeight: 'bold'}}>
                Call Us / Text Us
              </Text>
              <Text style={{fontSize: FS * 14, color: '#f1ead7', paddingBottom: FS * 5}}>
                During Business Hours
              </Text>
              <Text style={{fontSize: FS * 14, color: '#f1ead7', paddingBottom: FS * 5}}>
                Monday - Saturday 9am - 7pm
              </Text>
              <Text style={{fontSize: FS * 14, color: '#f1ead7', paddingBottom: FS * 5}}>
                Call us to schedule your detailing today!
              </Text>
              <TouchableOpacity onPress={() => callNumber('3365426266')} 
                style={{height: 50, width: 200, backgroundColor: '#619196', borderRadius: 10, justifyContent: 'center', alignItems: 'center'}}>
                <Text>Call 336.542.6266</Text>
              </TouchableOpacity>
            </Animated.View>
            { !smallScreen && <Animated.View style={{justifyContent: 'center', alignItems: 'center', opacity: homePageText2, top: smallScreen && FS*40}}>
              <Text style={{fontSize: FS * 20, color: '#f1ead7', paddingBottom: FS * 10, fontWeight: 'bold'}}>
                Email Us
              </Text>
              <Text style={{fontSize: FS * 14, color: '#f1ead7', paddingBottom: FS * 5}}>
                After Business Hours
              </Text>
              <Text style={{fontSize: FS * 14, color: '#f1ead7', paddingBottom: FS * 5}}>
                Sundays and after 7pm
              </Text>
              <Text style={{fontSize: FS * 14, color: '#f1ead7', paddingBottom: FS * 5}}>
                Email us to make an appointment!
              </Text>
              <TouchableOpacity onPress={() => switchTabs(3,'ContactScreen')}
                style={{height: 50, width: 200, backgroundColor: '#619196', borderRadius: 10, justifyContent: 'center', alignItems: 'center'}}>
                <Text>Email us</Text>
              </TouchableOpacity>
            </Animated.View>}
          </View>
        </Animated.View>
      </View>
      {smallScreen && <Animated.View style={{justifyContent: 'center', alignItems: 'center', opacity: homePageText2, top: smallScreen && FS*30,}}>
        <Text style={{fontSize: FS * 20, color: '#f1ead7', paddingBottom: FS * 10, fontWeight: 'bold'}}>
          Email Us
        </Text>
        <Text style={{fontSize: FS * 14, color: '#f1ead7', paddingBottom: FS * 5}}>
          After Business Hours
        </Text>
        <Text style={{fontSize: FS * 14, color: '#f1ead7', paddingBottom: FS * 5}}>
          Sundays and after 7pm
        </Text>
        <Text style={{fontSize: FS * 14, color: '#f1ead7', paddingBottom: FS * 5}}>
          Email us to make an appointment!
        </Text>
        <TouchableOpacity onPress={() => switchTabs(3, 'ContactScreen')}
          style={{height: 50, width: 200, backgroundColor: '#619196', borderRadius: 10, justifyContent: 'center', alignItems: 'center'}}>
          <Text>Email us</Text>
        </TouchableOpacity>
      </Animated.View>}
      <View style={{width: FSS.width, justifyContent: 'center', alignItems: 'center'}}>
      <Animated.View style={{opacity: whoWeAreFade, top: smallScreen ? FS*150 : FS*25}}>
        <Text style={{fontSize: FS * 40, color: '#f1ead7', paddingBottom: FS * 10, fontWeight: 'bold'}}>What we do</Text>
      </Animated.View>
        <View style={{flexDirection: !smallScreen && 'row', justifyContent: 'space-evenly',width: FSS.width, top: smallScreen ? FS*150 : FS*25, alignItems: smallScreen && 'center' }}>
          <Animated.View style={{opacity: card1,}}>
            <WhatWeDoCard
              switchTabs={props.switchTabs}
              image={require('../assets/4.jpg')}
              title='Basic Detailing'
              body="Detailing is at the core of Details of Greensboro. It's what most of our customers want: transforming a dirty or neglected car into one that looks fresh from the dealership. Our packages cover thorough interior and exterior cleaning, including foam bath, vacuuming, steam cleaning, conditioning, deodorizing, and more."
            />
          </Animated.View>
          <Animated.View style={{opacity: card2, top: smallScreen && FS*20}}>
            <WhatWeDoCard
              switchTabs={props.switchTabs}
              title='Paint Correction'
              image={require('../assets/3.jpg')}
              body="Over time, your car's paint faces the elements, losing shine and collecting scratches. Our paint correction service skillfully restores its brilliance, bringing back that showroom-worthy appearance with detailed polishing, ensuring a flawless finish to impress car enthusiasts."
            />
          </Animated.View>
          <Animated.View style={{opacity: card3,top: smallScreen && FS*40}}>
            <WhatWeDoCard
              switchTabs={props.switchTabs}
              title='Headlight Restoration'
              image={require('../assets/1.jpg')}
              body="At Details of Greensboro, we specialize in restoring cloudy headlights. Over time, headlights can become cloudy, reducing visibility. Our process removes haze and yellowing, bringing back clarity. Say goodbye to dull headlights, hello to enhanced safety and style."
            />
          </Animated.View>
        </View>
      </View>

      <View style={{height: 150, top: FS*300,borderTopWidth: .5, borderColor:'black', width: FSS.width, alignItems: 'center', justifyContent: 'center'}}>
        {/* <Image
          source={require('../assets/Logo1.png')}
          style={{height: FS * 50, width: FS * 50,  borderRadius: 10}}
        /> */}
        <Text style={{color: 'white', fontSize: FS * 15,}}>Copyright © 2024 Details of Greensboro</Text>
      </View>
    </View>
  );
}


const makeStyles = fontScale => StyleSheet.create({
  title: {
    fontFamily: 'CG-Times Bold', 
    fontSize: 100 * fontScale, 
    color: '#F60081', 
  },
  name: {
    fontFamily: 'CG-Times Bold', 
    fontSize: 30 * fontScale,  
    color: 'white',
    padding: 5,
  },
  button: {
    paddingRight: 20,
    paddingLeft: 20,
    padding: 15,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#eafbf2',
    borderRadius: 30,
    top: 25
  },
  buttonSong: {
    width: 375,
    height: 50,
    padding: 10,
    alignItems: 'center',
    backgroundColor: 'white',
    borderRadius: 30,
    flexDirection: 'row',
    marginTop: 20,
    alignSelf :'center'
  },
  buttonText: {
    fontSize: 15,
    fontFamily: 'CG-Times Bold', 
  },
  buttonSongText: {
    fontSize: 20,
    fontFamily: 'CG-Times Bold', 
    marginLeft: 15
  },
  email: {
    color: 'white', 
    fontFamily: 'CG-Times Bold', 
    position: 'absolute', 
    bottom: 25, 
    fontSize: 17
  }
});
