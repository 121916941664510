import { StatusBar } from 'expo-status-bar';
import  { useState, useEffect, useRef, useContext } from 'react'
import { StyleSheet, Text, View, Dimensions, TouchableOpacity, Pressable } from 'react-native';
import RootContainerNavigation from './RootContainerNavigation';
import { useFonts } from 'expo-font';
import { useNavigation } from '@react-navigation/native';
import { MainContext } from './Contexts/MainContext';
import Instagram from './common/Instagram';


const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

export default function WebsiteTabs(props) {
    const navigation = useNavigation();
    const {tabIndex, setTabIndex} = useContext(MainContext)
    const [fontsLoaded] = useFonts({
        'RoxerStencil': require('./assets/RoxerStencil.otf'),
      });

    useEffect(() => {
        if(props.headerTriggerd) switchTabs(0, 'HomeScreen')
    },[props.headerTriggerd])

    function switchTabs(index, screen){
        setTabIndex(index)
        props.switchTabs(screen)
        props.setHeaderTriggered(false)
    }


  return (
    <View style={styles.container}>
        <View style={{ paddingRight:30}}>
            <Pressable onPress={() => switchTabs(0,'HomeScreen')}>
                <Text style={{fontSize: 24, color: '#2a3614',fontWeight: 'bold', borderBottomColor: '#2a3614',fontWeight: 'bold', borderBottomWidth: tabIndex === 0 ? 1 : 0}}>HOME</Text>
            </Pressable>
        </View>
        <View style={{ paddingRight:30}}>
            <Pressable onPress={() => switchTabs(1,'ServicesScreen')}>
                <Text style={{fontSize: 24, color: '#2a3614',fontWeight: 'bold', borderBottomColor: '#2a3614',fontWeight: 'bold', borderBottomWidth: tabIndex === 1 ? 1 : 0}}>SERVICES</Text>
            </Pressable>
        </View>
         <View style={{ paddingRight:30}}>
            <Pressable onPress={() => {switchTabs(2,'AboutScreen'); props.setImageOrVideo(false)}}>
                <Text style={{fontSize: 24, color: '#2a3614',fontWeight: 'bold', borderBottomColor: '#2a3614',fontWeight: 'bold', borderBottomWidth: tabIndex === 2 ? 1 : 0,}}>ABOUT</Text>
            </Pressable>
        </View>
         <View style={{ paddingRight:30}}>
            <Pressable onPress={() => {switchTabs(3, 'ContactScreen'); props.setImageOrVideo(false)}}>
                <Text style={{fontSize: 24,  color: '#2a3614',fontWeight: 'bold', borderBottomColor: '#2a3614',fontWeight: 'bold', borderBottomWidth: tabIndex === 3 ? 1 : 0,}}>CONTACT</Text>
            </Pressable>
        </View>
        <Instagram/>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    backgroundColor: 'transparent',
    justifyContent: 'space-between',
    position:'absolute',
    width: 375,
    right: -75,
    top: -190,
  },
});
