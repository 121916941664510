import { StyleSheet, Text, View, useWindowDimensions, Dimensions, Linking, Image, Animated, Easing,} from 'react-native';
import { useFonts } from 'expo-font';
import { useEffect, useState, useRef, useCallback, useContext } from 'react';
import { TouchableOpacity } from 'react-native-web';
import WhatWeDoCardServices from '../common/WhatWeDoServices';
import Carousel from '../common/Carousel';
import { MainContext } from '../Contexts/MainContext';
import WhatWeDoCardServicesSmall from '../common/WhatWeDoCardServicesSmall';

const { height, width } = Dimensions.get('window');
// const fontScale = c

export default function Services(props) {
  const [FS, setFS] = useState(Math.min(window.innerWidth / 320, window.innerHeight / 640))
  const [FSS, setFSS] =  useState({ "width": Math.min(window.innerWidth), "height": Math.min(window.innerHeight)})
  const styles = makeStyles(FS); // pass in fontScale to the StyleSheet
  const smallScreen = FSS.width < 900;
  const [card1] = useState(new Animated.Value(0));
  const [card2] = useState(new Animated.Value(0));
  const [card3] = useState(new Animated.Value(0));
  const [whoWeAreFade] = useState(new Animated.Value(0));
  const {scrollPosition, setScrollPosition} = useContext(MainContext)

  useEffect(() => {
    animateServices()
    window.addEventListener('resize', changeTitleSize);
    return () => {
        window.removeEventListener('resize', changeTitleSize);
    }
  }, []);


  useEffect(() => {
    //console.log("SP", scrollPosition)
    if(scrollPosition >= 10) animateOtherServices()
    
  }, [scrollPosition]);


  function changeTitleSize(){
    setFS(Math.min(window.innerWidth / 320, window.innerHeight / 640))
    setFSS({ "width": Math.min(window.innerWidth), "height": Math.min(window.innerHeight)})
  }

  const sleep = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  function weScrolled(){
    Animated.timing(whoWeAreFade, {
      toValue: 1,
      duration: 2500,
    }).start();
  }


  async function animateServices(){
    // setEventListTriggered(true)
    Animated.timing(whoWeAreFade, {
      toValue: 1,
      duration: 2500,
    }).start();
    Animated.timing(card1, {
      toValue: 1,
      duration: 2500,
    }).start();
  }


  async function animateOtherServices(){
    Animated.timing(card2, {
      toValue: 1,
      duration: 2500,
    }).start();
    await sleep(250)
    Animated.timing(card3, {
      toValue: 1,
      duration: 2500,
    }).start();
  }

  return (
    <View style={{}}>
        <Carousel fromServices={true}/>
        {smallScreen && 
        <View style={{width: FSS.width, justifyContent: 'center', alignItems: 'center', top: FS*100, position: 'absolute'}}>
        <Animated.View style={{opacity: whoWeAreFade,}}>
          <Text style={{fontSize: FS * 40, color: '#f1ead7',fontWeight: 'bold'}}>What we offer</Text>
        </Animated.View>
      
        <Animated.View style={{opacity: card1,}}>
            <WhatWeDoCardServicesSmall
              switchTabs={props.switchTabs}
              bigCard={true}
              image={require('../assets/4.jpg')}
              title='Basic Detail Package'
              body1="Give your car's interior a deep clean and fresh scent with our vacuum and deodorize service."
              body2="We'll spruce up your dash, console, door panels, and trim to make your ride feel brand new."
              body3="Enjoy crystal-clear views and a dazzling exterior with our glass cleaning, hand wash, wax, and tire shine treatment."
            />
          </Animated.View>
          <Animated.View style={{opacity: card2,top: FS* 20}}>
              <WhatWeDoCardServicesSmall
                switchTabs={props.switchTabs}
                bigCard={true}
                image={require('../assets/3.jpg')}
                title='Paint Correction'
                body1="Starting at 400, call for a quote today"
              />
          </Animated.View>
          <Animated.View style={{opacity: card3,top: FS* 40}}>
              <WhatWeDoCardServicesSmall
                switchTabs={props.switchTabs}
                bigCard={true}
                image={require('../assets/1.jpg')}
                title='Headlight Restoration'
                body1="75$ without detail booking"
                body2="50$ at time of detail (must be scheduled when scheduling detail)"
              />
          </Animated.View>
      </View>
        }


        {!smallScreen && 
        <View style={{width: FSS.width, justifyContent: 'center', alignItems: 'center', top: FS*100, position: 'absolute'}}>
          <Animated.View style={{opacity: whoWeAreFade,}}>
            <Text style={{fontSize: FS * 40, color: '#f1ead7', paddingBottom: FS * 10, fontWeight: 'bold'}}>What we offer</Text>
          </Animated.View>
        
          <Animated.View style={{opacity: card1,top: FS* 20}}>
              <WhatWeDoCardServices
                switchTabs={props.switchTabs}
                bigCard={true}
                image={require('../assets/4.jpg')}
                title='Basic Detail Package'
                body1="Give your car's interior a deep clean and fresh scent with our vacuum and deodorize service."
                body2="We'll spruce up your dash, console, door panels, and trim to make your ride feel brand new."
                body3="Enjoy crystal-clear views and a dazzling exterior with our glass cleaning, hand wash, wax, and tire shine treatment."
              />
          </Animated.View>
        </View>
        }
        {!smallScreen && 
        <View style={{flexDirection: 'row', width: FSS*width, justifyContent: 'space-evenly', top: FS * 75}}>
          <Animated.View style={{opacity: card2,}}>
            <WhatWeDoCardServices
              switchTabs={props.switchTabs}
              bigCard={true}
              double={true}
              image={require('../assets/3.jpg')}
              title='Paint Correction'
              body1="Starting at 400, call for a quote today"
              body2=""
              body3=""
            />
          </Animated.View>
          <Animated.View style={{opacity: card3,}}>
            <WhatWeDoCardServices
              switchTabs={props.switchTabs}
              bigCard={true}
              double={true}
              image={require('../assets/1.jpg')}
              title='Headlight Restoration'
              body1="75$ without detail booking "
              body2="50$ if booked along with detail"
              body3=""
            />
          </Animated.View>
        </View>
       }

      <View style={{height: 150, top: smallScreen ? FS*1100 : FS*150,borderTopWidth: .5, borderColor:'black', width: FSS.width, alignItems: 'center', justifyContent: 'center'}}>
        {/* <Image
          source={require('../assets/Logo1.png')}
          style={{height: FS * 50, width: FS * 50,  borderRadius: 10}}
        /> */}
        <Text style={{color: 'white', fontSize: FS * 15,}}>Copyright © 2024 Details of Greensboro</Text>
      </View>   
 
    </View>
  );
}


const makeStyles = fontScale => StyleSheet.create({
  title: {
    fontFamily: 'CG-Times Bold', 
    fontSize: 100 * fontScale, 
    color: '#F60081', 
  },
  name: {
    fontFamily: 'CG-Times Bold', 
    fontSize: 30 * fontScale,  
    color: 'white',
    padding: 5,
  },
  button: {
    paddingRight: 20,
    paddingLeft: 20,
    padding: 15,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#eafbf2',
    borderRadius: 30,
    top: 25
  },
  buttonSong: {
    width: 375,
    height: 50,
    padding: 10,
    alignItems: 'center',
    backgroundColor: 'white',
    borderRadius: 30,
    flexDirection: 'row',
    marginTop: 20,
    alignSelf :'center'
  },
  buttonText: {
    fontSize: 15,
    fontFamily: 'CG-Times Bold', 
  },
  buttonSongText: {
    fontSize: 20,
    fontFamily: 'CG-Times Bold', 
    marginLeft: 15
  },
  email: {
    color: 'white', 
    fontFamily: 'CG-Times Bold', 
    position: 'absolute', 
    bottom: 25, 
    fontSize: 17
  }
});
