import { StatusBar } from 'expo-status-bar';
import { StyleSheet, Text, View, Dimensions, Pressable, TouchableOpacity, ActivityIndicator } from 'react-native';
import { useFonts } from 'expo-font';
import { useEffect, useState, useRef } from 'react';
import { TextInput } from 'react-native-web';
import { child, ref, set, get, getDatabase, onValue, update,} from "firebase/database";
import { db } from '../config';

const { height, width } = Dimensions.get('window');

export default function Contact() {
  const [FS, setFS] = useState(Math.min(window.innerWidth / 640, window.innerHeight / 640))
  const [FSS, setFSS] =  useState({ "width": Math.min(window.innerWidth), "height": Math.min(window.innerHeight)})
  const styles = makeStyles(FS); // pass in fontScale to the StyleSheet
  const [email, setEmail] = useState('')
  const [name, setName] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [message, setMessage] = useState('')
  const [emailError, setEmailError] = useState(false)
  const [isReadyToSend, setIsReadyToSend] = useState(false)
  const [emailSending, setEmailSending] = useState(false)
  const [bigError, setBigError] = useState(false)
  const [websiteCount, setWebsiteCount] = useState(false)
  


  useEffect(() => {
    ////console.log("we gonna try and verifiy")
    if(!verifyForm()) return setIsReadyToSend(false)
    ////console.log("its been verified!")
    return setIsReadyToSend(true)
  }, [email, name, phoneNumber, message]);

  useEffect(() => {
    readData()
    window.addEventListener('resize', changeTitleSize);
    return () => {
        window.removeEventListener('resize', changeTitleSize);
    }
  }, []);

  function readData(){
    const showsRef = ref(db, 'websiteCountDetailsOfGSO');
    onValue(showsRef, (snapshot) => {
      const data = snapshot.val();
      ////console.log("this the data tooo", data)
      setWebsiteCount(data)
    });
  }

  function writeData(){
    const data = websiteCount + 1
    const updates = {};
    const showsRef = ref(db);
    updates['/websiteCountDetailsOfGSO'] = data
    update(showsRef,updates);
  }

  function verifyForm(){
    if(name === '') return false;
    if(message === '') return false;
    if(email === ''){
      return false
    } else if(!validate(email)) return false;
    return true
  }

  const validate = (text) => {
    ////console.log(text);
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
    if (reg.test(text) === false) {
      ////console.log("Email is Not Correct");
      setEmailError(true)
      return false;
    } 
    else {
      setEmailError(false)
      ////console.log("Email is Correct");
      return true;
    }
  }

  function onSubmit(){
    const catchEmAll = async () => {
      try{
        setEmailSending(true)
        await fetch('https://ludoapi-2024.onrender.com/contactEmailDetailsOfGSO', {
          method: 'POST',
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            passphrase: "huyter",
            name: name,
            email: email,
            number: phoneNumber,
            subject: `Oh boi! Someone from the website is trying to contact you! #${websiteCount}`,
            content: message
          }),
        })
        writeData()
        setEmailSending(false)
        setBigError(false)
        setName('')
        setEmail('')
        setMessage('')
        setPhoneNumber('')
      } catch(e) {
        //console.log("error when sending email", e)
        setBigError(true)
        setEmailSending(false)
      }
    }
    catchEmAll()
}

  function changeTitleSize(){
    setFS(Math.min(window.innerWidth / 640, window.innerHeight / 640))
    setFSS({ "width": Math.min(window.innerWidth), "height": Math.min(window.innerHeight)})
  }
  
  return (
    <View style={[styles.container, {height: FSS.height, top: FSS.width < 900 ? FS * 100 : 0, }]}>
        <Text style={{fontSize: 14, color: 'white',}}>{bigError && 'Sorry, there was an issue submitting your form'}</Text>
        <View style={{ }}>
         
          <View style={{flexDirection: 'row'}}>
            <View>
              <View style={{paddingTop: 20, }}>
                <Text style={styles.title}>Name*</Text>
                <TextInput
                  value={name}
                  onChangeText={setName}
                  style={{backgroundColor: 'white', height: 25, width: FSS.width < 900 ? FS * 500 : 400, borderRadius: 5}}
                />
              </View>
              <View style={{paddingTop: 20, }}>
                <View style={{flexDirection: 'row'}}>
                  <Text style={styles.title}>Email*</Text>
                  <Text style={{left: 20, fontFamily: 'CG-Times Bold', fontSize: 20 * FS, color: 'white',}}>{emailError && 'Please enter a valid email'}</Text>
                </View>
                <TextInput
                  value={email}
                  onChangeText={setEmail}
                  style={{backgroundColor: 'white', height: 25, width: FSS.width < 900 ? FS * 500 : 400, borderRadius: 5}}
                />
              </View>
              <View style={{paddingTop: 20, }}>
                <Text style={styles.title}>Phone Number</Text>
                <TextInput
                  value={phoneNumber}
                  onChangeText={setPhoneNumber}
                  style={{backgroundColor: 'white', height: 25, width: FSS.width < 900 ? FS * 500 : 400, borderRadius: 5}}
                />
              </View>
            </View>
            <View style={{  justifyContent: 'center', alignItems: 'center' }}>
              <Text style={{fontSize: FS*22, width: FS*400, color: 'white', textAlign: 'center', marginBottom: FS*10}}>Got a question? We've got answers!</Text>
              <Text style={{fontSize: FS*18, width: FS*275, color: 'white', textAlign: 'left'}}>Whether you're curious about our services, need a quote, or just want to say hi, we're here to help. Send us a message and let's start a conversation. Your satisfaction is our priority at Details of Greensboro.</Text>
            </View>
          </View>







          <View style={{}}>
           <Text style={styles.title}>Message*</Text>
           <TextInput
              multiline
              value={message}
              onChangeText={setMessage}
              style={{backgroundColor: 'white', height: FSS.width < 900 ? 300 : 200, width: FSS.width < 900 ? FS * 500 : FS * 500, borderRadius: 5, padding: 5}}
           />
          </View> 
         </View>

         {emailSending && <ActivityIndicator size={100} style={{position: 'absolute', top: FS*300}} color={'grey'}/>}
         <View style={{marginBottom: 50}}>
        <TouchableOpacity activeOpacity={!isReadyToSend ? .2 : .2} disabled={!isReadyToSend} onPress={() => !isReadyToSend ? null : onSubmit()}
          style={{width: 300, height: 50, backgroundColor: !isReadyToSend ? 'gray' : '#619196', justifyContent: 'center', alignItems: 'center', borderRadius: 10, opacity: !isReadyToSend ? .4 : 1, top: 30,}}>
          <Text style={{color: 'white', fontSize: 18}}>Submit</Text>
        </TouchableOpacity>
        </View>
    </View>
  );
}

const makeStyles = fontScale => StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    fontFamily: 'CG-Times Bold', 
    fontSize: 20 * fontScale, 
    color: 'white',
    paddingBottom: 5
  },
  star: {
    fontFamily: 'CG-Times Bold', 
    fontSize: 20 * fontScale, 
    color: 'black',
    paddingBottom: 10
  },
});
