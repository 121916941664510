import { StatusBar } from 'expo-status-bar';
import { StyleSheet, Text, TouchableOpacity,Pressable, Image} from 'react-native';
import { useFonts } from 'expo-font';
import { useContext } from 'react';
import { useNavigation } from '@react-navigation/native';
import { MainContext } from './Contexts/MainContext';



export default function WebsiteHeader(props) {
  const navigation = useNavigation();
  const smallScreen = props.FS.width < 900
  const {tabIndex} = useContext(MainContext)
  const FS = props.FSS
  const [fontsLoaded] = useFonts({
    'RoxerStencil': require('./assets/RoxerStencil.otf'),
    'H': require('./assets/H.ttf'),
  });

  return (
    <Pressable style={{backgroundColor: 'transparent', position: 'absolute', top:smallScreen ? FS*25 : FS*15, left: FS* 20, zIndex: 101, flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}
    onPress={() => props.switchTabs('HomeScreen', false, true)}>
        {!smallScreen &&<Image
          source={require('./assets/Logo1.png')}
          style={{height: FS * 50, width: FS * 50, right: 20}}
        />}
        {smallScreen && <Text style={{ fontSize: FS*13, color: '#2a3614', fontWeight: 'bold', fontFamily: 'H'}}>Details of greensboro</Text>}
        {!smallScreen && <Text style={{ fontSize: FS*13, color: '#2a3614', fontWeight: 'bold', fontFamily: 'H'}}>Details of Greensboro</Text>}
    </Pressable>
  );
}

const styles = StyleSheet.create({

});
