import {
	FlatList,
	Image,
	StyleSheet,
	Text,
	View,
	Dimensions,
	LogBox,
} from "react-native";
import React, { useEffect, useRef, useState, useCallback } from "react";
import { LinearGradient } from 'expo-linear-gradient';

const Carousel = (props) => {
	const flatlistRef = useRef();
	// Get Dimesnions
	const [activeIndex, setActiveIndex] = useState(0);
    const [FS, setFS] = useState(Math.min(window.innerWidth / 320, window.innerHeight / 640))
    const [FSS, setFSS] =  useState({ "width": Math.min(window.innerWidth), "height": Math.min(window.innerHeight)})
    const screenWidth = FSS.width
		const smallScreen = FSS.width < 900

    useEffect(() => {
        window.addEventListener('resize', changeTitleSize);
        return () => {
            window.removeEventListener('resize', changeTitleSize);
        }
      }, []);

      function changeTitleSize(){
        setFS(Math.min(window.innerWidth / 320, window.innerHeight / 640))
        setFSS({ "width": Math.min(window.innerWidth), "height": Math.min(window.innerHeight)})
      }

	// Auto Scroll
	useEffect(() => {
		let interval = setInterval(() => {
            //console.log("acitveIndex", Math.round(activeIndex), "carouselData.length-1", carouselData.length-1)
			if (Math.round(activeIndex) === carouselData.length-1) {
                //console.log("we in here")
				flatlistRef.current.scrollToIndex({
					index: 0,
					animation: true,
				});
			} else {
                //console.log("nah we in here")
				flatlistRef.current.scrollToIndex({
					index: Math.round(activeIndex) + 1,
					animation: true,
				});
			}
		}, 3000);

		return () => clearInterval(interval);
	});

	const getItemLayout = (data, index) => (
	{
		length: screenWidth,
		offset: screenWidth * index, // for first image - 300 * 0 = 0pixels, 300 * 1 = 300, 300*2 = 600
		index: index,
	});
	// Data for carousel
	const carouselData = [
		{
			id: "01",
			image: smallScreen ? require("../assets/3.jpg") : require("../assets/wide1.png"),
		},
		{
			id: "02",
			image: smallScreen ? require("../assets/4.jpg") : require("../assets/wide.jpg"),
		},
		{
			id: "03",
			image: smallScreen ?  require("../assets/1.jpg") : require("../assets/wide2.png"),
		},
        {
			id: "04",
			image: smallScreen ? require("../assets/wide3.png") : require("../assets/3.jpg"),
		},
	];

	//  Display Images // UI
	const renderItem = ({ item, index }) => {
		return (
			<View style={{}}>
				<Image
					source={item.image}
					style={{ height: FS * 650, width:FSS.width }}
				/>
				{props?.fromServices && 
				 <LinearGradient
            colors={[ 'rgba(0,0,0,0.9)', 'transparent',]}//rgba(0,0,0,0.9)
            style={{ width: FSS.width, height: FSS.height/3, position: 'absolute', }}>
					</LinearGradient>}
				<LinearGradient
            colors={['transparent', 'rgba(0,0,0,0.9)']}//rgba(0,0,0,0.9)
            style={{ width: FSS.width, height: FSS.height/1.5, position: 'absolute', bottom: 0,}}>
        </LinearGradient>
			</View>
		);
	};

	// Handle Scroll
	const handleScroll = (event) => {
		// Get the scroll position
		const scrollPosition = event.nativeEvent.contentOffset.x;
		//console.log({ scrollPosition });
		// Get the index of current active item

		const index = scrollPosition / screenWidth;
		//console.log("index bby", index)

		//console.log(Math.round(index));
		// Update the index

		setActiveIndex(Math.round(index));
	};

	// Render Dot Indicators
	const renderDotIndicators = () => {
		return carouselData.map((dot, index) => {
			// if the active index === index

			if (activeIndex === index) {
				return (
					<View
						style={{
							backgroundColor: "green",
							height: 10,
							width: 10,
							borderRadius: 5,
							marginHorizontal: 6,
						}}
					></View>
				);
			} else {
				return (
					<View
						key={index}
						style={{
							backgroundColor: "red",
							height: 10,
							width: 10,
							borderRadius: 5,
							marginHorizontal: 6,
						}}
					></View>
				);
			}
		});
	};

	const _onViewableItemsChanged = useCallback(({ viewableItems, changed }) => {
		//console.log("Visible items are", viewableItems[0].index);
		setActiveIndex(viewableItems[0].index)
		//console.log("Visible items are", viewableItems);
		//console.log("Changed in this iteration", changed);
	}, []);

	const _viewabilityConfig = {
		itemVisiblePercentThreshold: 50
	}

	return (
		<View style={{width: FSS.width}}>
			<FlatList
				data={carouselData}
				ref={flatlistRef}
				getItemLayout={getItemLayout}
				renderItem={renderItem}
				keyExtractor={(item) => item.id}
				horizontal={true}
				pagingEnabled={true}
				// onScroll={handleScroll}
                showsHorizontalScrollIndicator={false}
				onViewableItemsChanged={_onViewableItemsChanged}
                viewabilityConfig={_viewabilityConfig}
			/>

			{/* <View
				style={{
					flexDirection: "row",
					justifyContent: "center",
					marginTop: 30,
				}}
			>
				{renderDotIndicators()}
			</View> */}
		</View>
	);
};

export default Carousel;

const styles = StyleSheet.create({});