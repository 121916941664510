import { StyleSheet, Text, View, useWindowDimensions, Dimensions, Linking, Image, Animated, Easing} from 'react-native';
import { useFonts } from 'expo-font';
import { useEffect, useState, useRef, useCallback, useContext } from 'react';
import { TouchableOpacity } from 'react-native-web';
import { useNavigation } from '@react-navigation/native';
import { MainContext } from '../Contexts/MainContext';

const { height, width } = Dimensions.get('window');
// const fontScale = c

export default function WhatWeDoCardServices(props) {
  const {tabIndex, setTabIndex} = useContext(MainContext)
  const [FS, setFS] = useState(Math.min(window.innerWidth / 320, window.innerHeight / 640))
  const [FSS, setFSS] =  useState({ "width": Math.min(window.innerWidth), "height": Math.min(window.innerHeight)})
  const styles = makeStyles(FS); // pass in fontScale to the StyleSheet
  const smallScreen = FSS.width < 900;
  const navigation = useNavigation();
  const h = props?.bigCard ? props?.double ? 275 : 450 : 450
  const w = props?.bigCard ? props?.double ? 450 : 550 : 200
  const imageH = props?.bigCard ? 200 : 175
  const imageW =  props?.bigCard ? props?.double ? 200 : 250 : 175
  const headerTextSize = 20
  const bodyTextSize = 12

  useEffect(() => {
    window.addEventListener('resize', changeTitleSize);
    return () => {
        window.removeEventListener('resize', changeTitleSize);
    }
  }, []);

  function changeTitleSize(){
    setFS(Math.min(window.innerWidth / 320, window.innerHeight / 640))
    setFSS({ "width": Math.min(window.innerWidth), "height": Math.min(window.innerHeight)})
  }

  function switchTabs(index, screen){
    setTabIndex(index)
    props.switchTabs(screen)
}
  if(props.bigCard){
    return (
      <View style={{ height: FS * h, width: FS * w, backgroundColor: '#EFE8D5', borderRadius: 20,}}>
        {props.title === 'Basic Detail Package' && <View style={{position: 'absolute', top: FS*20, right: FS*10, zIndex: 999}}>
          <Text style={{fontSize: FS* 10,  color: '#2a3614'}}>XL Truck/SUV Fee - $25-50</Text>
        </View>}
      <View style={{top: FS* 15,  zIndex: 998, backgroundColor: '#EFE8D5',}}>
        {props.title === 'Basic Detail Package' ?  <Text style={{fontSize: FS * headerTextSize, fontWeight: 'bold', color: '#2a3614', left: FS * 20, paddingBottom: FS* 5}}>{props.title}</Text>
        :
         <Text style={{fontSize: FS * headerTextSize, fontWeight: 'bold', color: '#2a3614', left: FS * 20, paddingBottom: FS* 5}}>{props.title}</Text>
        }

        <View style={{flexDirection: props?.double && 'row', top: props.double && FS*10, left: props.double && FS*20}}>
          {props.double &&         
             <Image
                source={props.image}
                style={{height: FS*imageH, width: FS*imageW, borderRadius: 10 }}
              />
            }
          <View>

            {props?.title === 'Paint Correction' && 
            <View style={{}}>
                <Text style={{fontSize: FS* bodyTextSize,  color: '#2a3614', paddingHorizontal: 30, width: FS*225, marginBottom: FS*5}}>Paint correction is a meticulous process that rejuvenates a car's paint by removing imperfections like swirl marks and scratches, using specialized tools such as polishers, abrasive compounds, foam pads, and microfiber towels.</Text>
            </View>
            }
            {props?.title === 'Headlight Restoration' && 
            <View style={{}}>
                <Text style={{fontSize: FS* bodyTextSize,  color: '#2a3614', paddingHorizontal: 30, width: FS*225, marginBottom: FS*5}}>
Headlight restoration swiftly revives cloudy or yellowed headlights, enhancing visibility and appearance using materials like sandpaper, polishing compound, and UV sealant.</Text>
            </View>
            }
            {props?.body1 && 
            <View style={{  flexDirection: 'row', left: props?.title === 'Basic Detail Package' && FS*15, width: props.double ? FS*185 : (FS * w) - 30,alignSelf: 'center'}}>
              <Text style={{top: FS*8, fontSize: FS* 7}}>⬤</Text>
              <Text style={{fontSize: FS* bodyTextSize,  color: '#2a3614', padding: 10, }}>{props?.body1}</Text>
            </View>}

            {props?.body2 && 
            <View style={{  flexDirection: 'row', left: props?.title === 'Basic Detail Package' && FS*15, width: props.double ? FS*185 : (FS * w) - 30,alignSelf: 'center'}}>
              <Text style={{top: FS*8, fontSize: FS* 7}}>⬤</Text>
              <Text style={{fontSize: FS* bodyTextSize,  color: '#2a3614', padding: 10, }}>{props?.body2}</Text>
            </View>}

            {props?.body3 && 
            <View style={{  flexDirection: 'row', left: props?.title === 'Basic Detail Package' && FS*15, width: props.double ? FS*185 : (FS * w) - 30, alignSelf: 'center'}}>
              <Text style={{top: FS*8, fontSize: FS* 7}}>⬤</Text>
              <Text style={{fontSize: FS* bodyTextSize,  color: '#2a3614', padding: 10, }}>{props?.body3}</Text>
            </View>}

            {props.double &&         
            <TouchableOpacity style={{height: 50, width: FS * 185, backgroundColor: '#619196', borderRadius: 10, justifyContent: 'center', 
                                      alignItems: 'center', position: 'absolute', left: FS*20, bottom: 0 }} 
                  onPress={() => switchTabs(1, 'ContactScreen')}>
                <Text>Schedule now / Ask Questions !</Text>
            </TouchableOpacity>}
          </View>
        </View>
      </View>

        <View style={{flexDirection: 'row',left: FS*30, top: FS*15}}>
        {props.title === 'Basic Detail Package' &&
          <View style={{ alignItems:'center', justifyContent: 'center', width: FS*240, height: FS*210}}>
            <View style={{justifyContent:'center', alignItems: 'center', paddingBottom: FS*20}}>
              <Text style={{fontSize: FS* 16,  color: '#2a3614', fontWeight: 'bold',textAlign: 'center', width: FS*225 }}>Consecutive Monthly Customer</Text>
              <Text style={{fontSize: FS* 10,  color: '#2a3614',paddingTop: FS*5, textAlign: 'center'}}>*XL Truck/SUV Fee*</Text>
              <Text style={{fontSize: FS* 13,  color: '#2a3614',paddingTop: FS*5  }}>$100</Text>
            </View>
            <View style={{justifyContent:'center', alignItems: 'center', paddingBottom: FS*20}}>
              <Text style={{fontSize: FS* 16,  color: '#2a3614', fontWeight: 'bold' }}>Basic Package</Text> 
              <Text style={{fontSize: FS* 10,  color: '#2a3614',paddingTop: FS*5, textAlign: 'center'}}>*depending on factors such as: mud, dog hair, exterior mold, excessive personal belongings etc. XL Truck/SUV Fee*</Text>
              <Text style={{fontSize: FS* 13,  color: '#2a3614',paddingTop: FS*5  }}>$125-175</Text>
            </View> 
          </View>
          }

          <View style={{}}>
          {props.title === 'Basic Detail Package' &&
          <View style={{ left: FS*70,  width: FS*150,}}>
            <View style={{justifyContent:'center',top: FS*7 }}>
              <Text style={{fontSize: FS* 16,  color: '#2a3614', fontWeight: 'bold', paddingBottom: FS*5, }}>Steaming - <Text style={{fontSize: FS* 16,  color: '#2a3614',top: FS*1}}>$50</Text></Text>
              <View style={{  flexDirection: 'row', left: FS*20, width: (FS * w) - 15}}>
                <Text style={{top: FS*7, fontSize: FS* 5}}>⬤</Text>
                <Text style={{fontSize: FS* bodyTextSize,  color: '#2a3614', padding: 5, }}>Entire interior</Text>
              </View>
              <View style={{  flexDirection: 'row', left: FS*20, width: (FS * w) - 15}}>
                <Text style={{top: FS*7, fontSize: FS* 5}}>⬤</Text>
                <Text style={{fontSize: FS* bodyTextSize,  color: '#2a3614', padding: 5, }}>Consoles</Text>
              </View>
              <View style={{  flexDirection: 'row', left: FS*20, width: (FS * w) - 15}}>
                <Text style={{top: FS*7, fontSize: FS* 5}}>⬤</Text>
                <Text style={{fontSize: FS* bodyTextSize,  color: '#2a3614', padding: 5, }}>Dashboard</Text>
              </View>
              <View style={{  flexDirection: 'row', left: FS*20, width: (FS * w) - 15}}>
                <Text style={{top: FS*7, fontSize: FS* 5}}>⬤</Text>
                <Text style={{fontSize: FS* bodyTextSize,  color: '#2a3614', padding: 5, }}>Door panels</Text>
              </View>
              <View style={{  flexDirection: 'row', left: FS*20, width: (FS * w) - 15}}>
                <Text style={{top: FS*7, fontSize: FS* 5}}>⬤</Text>
                <Text style={{fontSize: FS* bodyTextSize,  color: '#2a3614', padding: 5, }}>Steering wheel</Text>
              </View>
            </View>
          </View>}
          {props.title === 'Basic Detail Package' &&
          <View style={{ left: FS*70, top: FS*20 }}>
            <View style={{justifyContent:'center',  paddingBottom: FS*10,}}>
              <Text style={{fontSize: FS* 16,  color: '#2a3614', fontWeight: 'bold', paddingBottom: FS*5,right: FS* 40 }}>Steam and Shampoo - <Text style={{fontSize: FS* 16,  color: '#2a3614',top: FS*1}}>$100</Text></Text>
              <View style={{  flexDirection: 'row',  width: (FS * w) - 15, alignSelf: 'center', right: FS*40}}>
                <Text style={{fontSize: FS* 10,  color: '#2a3614', textAlign: 'center', paddingBottom: FS*5}}>*includes 1 of the 3 below. Contact for more</Text>
              </View>
              <View style={{  flexDirection: 'row', left: FS*20, width: (FS * w) - 15, alignSelf: 'center'}}>
                <Text style={{top: FS*7, fontSize: FS* 5}}>⬤</Text>
                <Text style={{fontSize: FS* bodyTextSize,  color: '#2a3614', padding: 5, }}>Floor mats</Text>
              </View>
              <View style={{  flexDirection: 'row', left: FS*20, width: (FS * w) - 15}}>
                <Text style={{top: FS*7, fontSize: FS* 5}}>⬤</Text>
                <Text style={{fontSize: FS* bodyTextSize,  color: '#2a3614', padding: 5, }}>Floors</Text>
              </View>
              <View style={{  flexDirection: 'row', left: FS*20, width: (FS * w) - 15}}>
                <Text style={{top: FS*7, fontSize: FS* 5}}>⬤</Text>
                <Text style={{fontSize: FS* bodyTextSize,  color: '#2a3614', padding: 5, }}>Seats</Text>
              </View>
            </View>
          </View>}
          </View>
        </View>

        {!props.double &&         
        <TouchableOpacity style={{height: 50, width: FS * 200, backgroundColor: '#619196', borderRadius: 10, justifyContent: 'center', 
                                  alignItems: 'center', alignSelf: 'center', top: FS*35 }} 
              onPress={() => switchTabs(1, 'ContactScreen')}>
            <Text>Schedule now / Ask Questions !</Text>
        </TouchableOpacity>}

    </View>
    )
  } else return (
    <View style={{ alignItems: 'center', height: FS * h, width: FS * w, backgroundColor: '#f1ead7', borderRadius: 20}}>
      <View style={{padding: 10}}>
        <Image
          source={props.image}
          style={{height: FS*imageH, width: FS*imageW, borderRadius: 1000}}
        />
      </View>
      <Text style={{fontSize: FS * headerTextSize, fontWeight: 'bold', color: '#2a3614', textAlign: 'center'}}>{props.title}</Text>
      
      <View style={{  flexDirection: 'row', left: FS*10, width: (FS * w) - 15}}>
        <Text style={{top: FS*8}}>⬤</Text>
        <Text style={{fontSize: FS* bodyTextSize,  color: '#2a3614', padding: 10, }}>{props?.body1}</Text>
      </View>

      <View style={{  flexDirection: 'row', left: FS*10, width: (FS * w) - 15}}>
        <Text style={{top: FS*8}}>⬤</Text>
        <Text style={{fontSize: FS* bodyTextSize,  color: '#2a3614', padding: 10, }}>{props?.body2}</Text>
      </View>

      <View style={{  flexDirection: 'row', left: FS*10, width: (FS * w) - 15}}>
        <Text style={{top: FS*8}}>⬤</Text>
        <Text style={{fontSize: FS* bodyTextSize,  color: '#2a3614', padding: 10, }}>{props?.body3}</Text>
      </View>
      
    </View>
  );
}


const makeStyles = fontScale => StyleSheet.create({
  title: {
    fontFamily: 'CG-Times Bold', 
    fontSize: 100 * fontScale, 
    color: '#F60081', 
  },
  name: {
    fontFamily: 'CG-Times Bold', 
    fontSize: 30 * fontScale,  
    color: 'white',
    padding: 5,
  },
  button: {
    paddingRight: 20,
    paddingLeft: 20,
    padding: 15,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#eafbf2',
    borderRadius: 30,
    top: 25
  },
  buttonSong: {
    width: 375,
    height: 50,
    padding: 10,
    alignItems: 'center',
    backgroundColor: 'white',
    borderRadius: 30,
    flexDirection: 'row',
    marginTop: 20,
    alignSelf :'center'
  },
  buttonText: {
    fontSize: 15,
    fontFamily: 'CG-Times Bold', 
  },
  buttonSongText: {
    fontSize: 20,
    fontFamily: 'CG-Times Bold', 
    marginLeft: 15
  },
  email: {
    color: 'white', 
    fontFamily: 'CG-Times Bold', 
    position: 'absolute', 
    bottom: 25, 
    fontSize: 17
  }
});
