import { StyleSheet, Text, View, useWindowDimensions, Dimensions, Linking, Image, Animated, Easing} from 'react-native';
import { useFonts } from 'expo-font';
import { useEffect, useState, useRef, useCallback } from 'react';
import { LinearGradient } from 'expo-linear-gradient';

const { height, width } = Dimensions.get('window');
// const fontScale = c

export default function About(props) {
  const [FS, setFS] = useState(Math.min(window.innerWidth / 320, window.innerHeight / 640))
  const [FSS, setFSS] =  useState({ "width": Math.min(window.innerWidth), "height": Math.min(window.innerHeight)})
  const styles = makeStyles(FS); // pass in fontScale to the StyleSheet
  const isIphone = FSS.width < 900;
  const scaledFontSize = FSS.width < 900 ? 25 * FS : 15 * FS 

  useEffect(() => {
    window.addEventListener('resize', changeTitleSize);
    return () => {
        window.removeEventListener('resize', changeTitleSize);
    }
  }, []);

  function changeTitleSize(){
    setFS(Math.min(window.innerWidth / 320, window.innerHeight / 640))
    setFSS({ "width": Math.min(window.innerWidth), "height": Math.min(window.innerHeight)})
  }

  return (
    <View style={{ alignItems: 'center',justifyContent: 'center',flex: 1}}>
      
    <View style={{flexDirection: 'row', width: FSS.width, height: FSS.height,}}>
      <View style={{flex: 1,}}>
        <Image
          source={require('../assets/4.jpg')}
          style={{height: FSS.height / 1.5, width: FSS.width/3,}}
        />
        <Image
          source={require('../assets/3.jpg')}
          style={{height: FSS.height / 1.5, width: FSS.width/3,}}
        />
        <Image
          source={require('../assets/1.jpg')}
          style={{height: FSS.height / 1.5, width: FSS.width/3,}}
        />
      </View>
      
      <View style={{flex: 2}}>
        <Image
          source={require('../assets/wide4.png')}
          style={{height: FSS.height / 2, width: FSS.width/1.5,}}
        />
        <Image
          source={require('../assets/wide1.png')}
          style={{height: FSS.height / 2, width: FSS.width/1.5,}}
        />
        <Image
          source={require('../assets/wide2.png')}
          style={{height: FSS.height / 2, width: FSS.width/1.5,}}// my scaling. pretty good right. uep. ik
        />
        <Image
          source={require('../assets/wide.jpg')}
          style={{height: FSS.height / 2, width: FSS.width/1.5,}}// my scaling. pretty good right. uep. ik
        />
      </View>
    </View>

    <LinearGradient
            colors={['rgba(0,0,0,0.9)', 'transparent', ]}//rgba(0,0,0,0.9)
            style={{ width: FSS.width, height: isIphone ? FSS.height : FSS.height*1.5, position: 'absolute', top: 0,}}>
    </LinearGradient>

    <Text style={{textAlign: 'center' ,fontFamily: 'CG-Times Bold', fontSize: FSS.width < 900 ? (45 * FS) : (35 * FS), color: 'white', position: 'absolute', left: FS*30, top: FSS.width < 800 ? FS*100 : FS*100,}}>Life is messy but your car doesn't have to be!</Text>
    <View style={{position: 'absolute', top: FSS.width < 900 ? FS*300 : FS*150}}>
      <View style={{ backgroundColor: '#eafbf2', padding: 30, borderRadius: 10, width: FSS.width < 900 ? FSS.width/1.2 : FSS.width /2, bottom: 0, opacity: .8, }}>
        <Text style={{ fontFamily: 'CG-Times', fontSize: scaledFontSize, color: 'black'}}>
        Details of Greensboro is a new school idea with the values of that old school work ethic that only seems harder and harder to find. Here at D.O.G, our main mission is comfort. We believe that your car is one of the most important things to keep clean. Whether it’s a Friday evening cruise with the radio blasting or a hectic morning with kids cramming in to make it to school on time, we’ve got you covered.
        </Text>
      </View>

      <View style={{ backgroundColor: '#eafbf2', padding: 30, borderRadius: 10, width: FSS.width < 900 ? FSS.width/1.2 : FSS.width /2, bottom: 0, opacity: .8, marginTop: 40}}>
        <Text style={{ fontFamily: 'CG-Times', fontSize: scaledFontSize, color: 'black', }}>
        Our goal is to keep your vehicle clean and your mind with one less thing to worry about. We take pride in each step of the detailing process to ensure your ride comes out great everytime but most importantly you are our priority. This company was built on communicating with the people around me and getting to know my neighbors one mess at a time.
        </Text>
      </View>
    </View>

  </View>
  );
}


const makeStyles = fontScale => StyleSheet.create({
  title: {
    fontFamily: 'CG-Times Bold', 
    fontSize: 100 * fontScale, 
    color: '#F60081', 
  },
  name: {
    fontFamily: 'CG-Times Bold', 
    fontSize: 30 * fontScale,  
    color: 'white',
    padding: 5,
  },
  button: {
    paddingRight: 20,
    paddingLeft: 20,
    padding: 15,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#eafbf2',
    borderRadius: 30,
    top: 25
  },
  buttonSong: {
    width: 375,
    height: 50,
    padding: 10,
    alignItems: 'center',
    backgroundColor: 'white',
    borderRadius: 30,
    flexDirection: 'row',
    marginTop: 20,
    alignSelf :'center'
  },
  buttonText: {
    fontSize: 15,
    fontFamily: 'CG-Times Bold', 
  },
  buttonSongText: {
    fontSize: 20,
    fontFamily: 'CG-Times Bold', 
    marginLeft: 15
  },
  email: {
    color: 'white', 
    fontFamily: 'CG-Times Bold', 
    position: 'absolute', 
    bottom: 25, 
    fontSize: 17
  }
});
