import React, {createContext, Component} from 'react';

export const MainContext = createContext();

class MainContextProvider extends Component {
    state = { 
        scrollPosition: 0,
        tabIndex: 0
     } 

    //  componentDidUpdate(prevProps, prevState) {
    //     if (prevState.scrollPosition !== this.state.scrollPosition) {
    //       ////console.log('scrollPosition state has changed. ' + JSON.stringify(this.state.scrollPosition))
    //     } else ////console.log('scrollPosition state has NOT changed.')
    //   }


    setScrollPosition = (y) => {
      this.setState({scrollPosition: y})
    }

    setTabIndex = (index) => {
        this.setState({tabIndex: index})
      }

    render() { 
        return ( 
            <MainContext.Provider 
                value={{
                ...this.state, 
                setScrollPosition: this.setScrollPosition,
                setTabIndex: this.setTabIndex
            }}
            >
                {this.props.children}
            </MainContext.Provider>
        );
    }
}
 
export default MainContextProvider;

