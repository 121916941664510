import  { useState, useEffect, useRef, useContext } from 'react'
import { StyleSheet, Text, View, Dimensions, Pressable } from 'react-native';
import Home from './Tabs/Home';
import Contact from './Tabs/Contact';
import SmallContact from './Tabs/SmallContact';
import Services from './Tabs/Services';
import About from './Tabs/About'
import TopHeader from './TopHeader';
import WebsiteHeader from './WebsiteHeader';
import WebsiteTabs from './WebsiteTabs';
import HamburgerMenu from './HamburgerMenu';
import {isMobile} from 'react-device-detect';
import Modal from "react-native-modal";
import FadeInText from './common/FadeInText';
import HamburgerMenuExpanded from './HamburgerMenuExpanded';
import { ScrollView } from 'react-native-web';
import Wave from './Wave';
import { MainContext } from './Contexts/MainContext';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

export default function RootScreen() {
    const [tab, setTab] = useState('HomeScreen')
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);
    const [menuActivated, setMenuActivated] = useState(false)
    const [headerTriggerd, setHeaderTriggered] = useState();
    const [isFirstRender, setIsFirstRender] = useState(true)
    const [imageOrVideo, setImageOrVideo] = useState(true)
    const {scrollPosition, setScrollPosition} = useContext(MainContext)
    const [eventListTriggered, setEventListTriggered] = useState(false)
    const [isScrolling, setIsScrolling] = useState(false)
    const scrollYRef = useRef(0)
    const scrollRef = useRef(0)

    function handleWindowSizeChange() {
        setWidth( Dimensions.get('window').width);
        setHeight(Dimensions.get('window').height);
    }

    

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    useEffect(() => {
      scrollRef.current?.scrollTo({
        y: 0,
        animated: false,
      });
      //console.log(tab)
    },[tab])

    function switchTabs(screen, fromMenu, fromHeader){
        //console.log("switching tabs", screen, fromMenu, fromHeader)
        setTab(screen)
        if(fromMenu) setMenuActivated(false);
        if(fromHeader) setHeaderTriggered(true);
    }
  return (
    <View style={styles.container}>
        {(menuActivated && width < 900) && 
          <HamburgerMenuExpanded 
            switchTabs={switchTabs} 
            width={width} 
            height={height}
            setImageOrVideo={setImageOrVideo}
            tab={tab}
          />
        }
        <TopHeader switchTabs={switchTabs} width={width} headerTriggerd={headerTriggerd} setImageOrVideo={setImageOrVideo}
          setHeaderTriggered={setHeaderTriggered}  menuActivated={menuActivated} setMenuActivated={setMenuActivated} />
        <ScrollView scrollEventThrottle={16} ref={scrollRef} onScroll={(event) => {
          // 0 means the top of the screen, 100 would be scrolled 100px down
          const currentYPosition = event.nativeEvent.contentOffset.y
          const oldPosition = scrollYRef.current
          if(oldPosition < currentYPosition) {
              setIsScrolling(true)
              if(eventListTriggered) return;
              if(currentYPosition >= 550) {
                setEventListTriggered(true)
                setScrollPosition(currentYPosition)
              }
              ////console.log("we mmovine down", currentYPosition)
          } else {
              setIsScrolling(true)
              ////console.log("we mmovine up", currentYPosition)
          }
          scrollYRef.current = currentYPosition
        }} style={{flex: 1, }}>
        {tab === 'HomeScreen' && <Home isFirstRender={isFirstRender} setIsFirstRender={setIsFirstRender} imageOrVideo={imageOrVideo} switchTabs={switchTabs}/>}
        {tab === 'ServicesScreen' && <Services switchTabs={switchTabs}/>}
        {tab === 'ContactScreen' && (width < 900 ? <SmallContact/> : <Contact/>)}
        {tab === 'AboutScreen' && <About/>}
        </ScrollView> 

        
        {/* <WebsiteHeader switchTabs={switchTabs}/>
        {width < 900 ? 
        <HamburgerMenu menuActivated={menuActivated} setMenuActivated={setMenuActivated}/> 
        : 
        <WebsiteTabs 
          headerTriggerd={headerTriggerd} 
          setHeaderTriggered={setHeaderTriggered} 
          switchTabs={switchTabs}
        />
        } */}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#2a3614',
  },               
  fadeText: {                   
    flex: 5,
    alignItems: 'center',               
    justifyContent: 'center'
  }
});
