import { StyleSheet, Text, View, useWindowDimensions, Dimensions, Linking, Image, Animated, Easing} from 'react-native';
import { useFonts } from 'expo-font';
import { useEffect, useState, useRef, useCallback, useContext } from 'react';
import { TouchableOpacity } from 'react-native-web';
import { useNavigation } from '@react-navigation/native';
import { MainContext } from '../Contexts/MainContext';

const { height, width } = Dimensions.get('window');
// const fontScale = c

export default function WhatWeDoCardServicesSmall(props) {
  const {tabIndex, setTabIndex} = useContext(MainContext)
  const [FS, setFS] = useState(Math.min(window.innerWidth / 320, window.innerHeight / 640))
  const [FSS, setFSS] =  useState({ "width": Math.min(window.innerWidth), "height": Math.min(window.innerHeight)})
  const styles = makeStyles(FS); // pass in fontScale to the StyleSheet
  const smallScreen = FSS.width < 900;
  const navigation = useNavigation();
  const h =  425
  const w = 300
  const imageH =  100
  const imageW =  100
  const headerTextSize = 20
  const bodyTextSize = 12

  useEffect(() => {
    window.addEventListener('resize', changeTitleSize);
    return () => {
        window.removeEventListener('resize', changeTitleSize);
    }
  }, []);

  function changeTitleSize(){
    setFS(Math.min(window.innerWidth / 320, window.innerHeight / 640))
    setFSS({ "width": Math.min(window.innerWidth), "height": Math.min(window.innerHeight)})
  }

  function switchTabs(index, screen){
    setTabIndex(index)
    props.switchTabs(screen)
}

  return (
      <View style={{ paddingTop: 40, paddingHorizontal: 20, backgroundColor: '#EFE8D5', borderRadius: 20, width: FS*300}}>
      
      <View style={{top: FS*-10,  zIndex: 999,}}>
      
        <Text style={{fontSize: FS * headerTextSize, fontWeight: 'bold', color: '#2a3614', left: FS * 10, paddingBottom: props.double ? FS* 25 : FS*15}}>{props.title}</Text>

        <View style={{flexDirection: props?.double ? 'row' : null}}>
          {props.double &&            
             <Image
                source={props.image}
                style={{height: FS*imageH, width: FS*imageW, borderRadius: 10, paddingBottom: 20 }}
              />
          }

          <View style={{left: FS*10}}>
            {props?.title === 'Paint Correction' && 
            <View style={{}}>
                <Text style={{fontSize: FS* bodyTextSize,  color: '#2a3614', width: FS*250, marginBottom: FS*5}}>Paint correction is a meticulous process that rejuvenates a car's paint by removing imperfections like swirl marks and scratches, using specialized tools such as polishers, abrasive compounds, foam pads, and microfiber towels.</Text>
            </View>
            }
            {props?.title === 'Headlight Restoration' && 
            <View style={{}}>
              <Text style={{fontSize: FS* bodyTextSize,  color: '#2a3614', width: FS*250, marginBottom: FS*5}}>
                  Headlight restoration swiftly revives cloudy or yellowed headlights, enhancing visibility and appearance using materials like sandpaper, polishing compound, and UV sealant.</Text>
            </View>
            }
          </View>
          <View style={{paddingBottom: props?.title !== "Basic Detail Package" && FS*30}}>
            {props?.body1 && 
            <View style={{  flexDirection: 'row', left: FS*10, width: props.double ? FS*175 : (FS * w) - 30, }}>
              <Text style={{top: FS*8, fontSize: FS*7, top: FS*13}}>⬤</Text>
              <Text style={{fontSize: FS* bodyTextSize,  color: '#2a3614', padding: 10, }}>{props?.body1}</Text>
            </View>}

            {props?.body2 && 
            <View style={{  flexDirection: 'row', left: FS*10, width: props.double ? FS*175 : (FS * w) - 30, }}>
              <Text style={{top: FS*8, fontSize: FS*7, top: FS*13}}>⬤</Text>
              <Text style={{fontSize: FS* bodyTextSize,  color: '#2a3614', padding: 10, }}>{props?.body2}</Text>
            </View>}

            {props?.body3 && 
            <View style={{  flexDirection: 'row', left: FS*10, width: props.double ? FS*175 : (FS * w) - 30, }}>
              <Text style={{top: FS*8, fontSize: FS*7, top: FS*13}}>⬤</Text>
              <Text style={{fontSize: FS* bodyTextSize,  color: '#2a3614', padding: 10, }}>{props?.body3}</Text>
            </View>}
          </View>
        </View>
      </View>

        <View style={{}}>
        {props.title === 'Basic Detail Package' &&
          <View style={{ alignItems:'center', justifyContent: 'center', width: (FS*300) - 20, height: FS*210}}>
            <View style={{justifyContent:'center', alignItems: 'center', paddingBottom: FS*20}}>
              <Text style={{fontSize: FS* 16,  color: '#2a3614', fontWeight: 'bold',textAlign: 'center', width: FS*225 }}>Consecutive Monthly Customer</Text>
              <Text style={{fontSize: FS* 10,  color: '#2a3614',paddingTop: FS*5, textAlign: 'center'}}>*XL Truck/SUV Fee $25-50*</Text>
              <Text style={{fontSize: FS* 13,  color: '#2a3614',paddingTop: FS*5  }}>$100</Text>
            </View>
            <View style={{justifyContent:'center', alignItems: 'center',}}>
              <Text style={{fontSize: FS* 16,  color: '#2a3614', fontWeight: 'bold' }}>Basic Package</Text> 
              <Text style={{fontSize: FS* 10,  color: '#2a3614',paddingTop: FS*5, textAlign: 'center'}}>*depending on factors such as: mud, dog hair, exterior mold, excessive personal belongings etc. XL Truck/SUV Fee $25-50*</Text>
              <Text style={{fontSize: FS* 13,  color: '#2a3614',paddingTop: FS*5  }}>$125-175</Text>
            </View> 
          </View>
          }
          {props.title === 'Basic Detail Package' &&
          <View style={{paddingBottom: FS*50, top: FS* 10  }}>
            <View style={{ }}>
              <View style={{justifyContent:'center', width: (FS*300) - 20, paddingBottom: FS*20}}>{/* 20 = padding*2 of parent View  */}

                <Text style={{fontSize: FS* 16,  color: '#2a3614', fontWeight: 'bold', paddingBottom: FS*5,  textAlign: 'center' }}>Steaming - <Text style={{fontSize: FS* 16,  color: '#2a3614',top: FS*1}}>$75</Text></Text>
                <View style={{alignSelf: 'center'}}>
                  <View style={{  flexDirection: 'row', left: FS*10, }}>
                    <Text style={{top: FS*10, fontSize: FS* 5,}}>⬤</Text>
                    <Text style={{fontSize: FS* bodyTextSize,  color: '#2a3614', padding: 5, }}>Entire interior</Text>
                  </View>
                  <View style={{  flexDirection: 'row', left: FS*10, }}>
                    <Text style={{top: FS*10, fontSize: FS* 5,}}>⬤</Text>
                    <Text style={{fontSize: FS* bodyTextSize,  color: '#2a3614', padding: 5, }}>Consoles</Text>
                  </View>
                  <View style={{  flexDirection: 'row', left: FS*10, }}>
                    <Text style={{top: FS*10, fontSize: FS* 5,}}>⬤</Text>
                    <Text style={{fontSize: FS* bodyTextSize,  color: '#2a3614', padding: 5, }}>Dashboard</Text>
                  </View>
                  <View style={{  flexDirection: 'row', left: FS*10, }}>
                    <Text style={{top: FS*10, fontSize: FS* 5,}}>⬤</Text>
                    <Text style={{fontSize: FS* bodyTextSize,  color: '#2a3614', padding: 5, }}>Door panels</Text>
                  </View>
                  <View style={{  flexDirection: 'row', left: FS*10, }}>
                    <Text style={{top: FS*10, fontSize: FS* 5,}}>⬤</Text>
                    <Text style={{fontSize: FS* bodyTextSize,  color: '#2a3614', padding: 5, }}>Steering wheel</Text>
                  </View>
                </View>

              </View>
            </View>

            <View style={{  width: (FS*300) - 20 }}>
              <View style={{justifyContent:'center',  paddingBottom: FS*20, }}>

               <Text style={{fontSize: FS* 16,  color: '#2a3614', fontWeight: 'bold', paddingBottom: FS*5, textAlign: 'center'}}>Steam and Shampoo - <Text style={{fontSize: FS* 16,  color: '#2a3614',top: FS*1}}>$100</Text></Text>
               <Text style={{fontSize: FS* 10,  color: '#2a3614', textAlign: 'center', paddingBottom: FS*5}}>*includes 1 of the 3 below. Contact for more</Text>
               <View style={{alignSelf: 'center'}}>
                <View style={{  flexDirection: 'row',  }}>
                  <Text style={{top: FS*10, fontSize: FS* 5}}>⬤</Text>
                  <Text style={{fontSize: FS* bodyTextSize,  color: '#2a3614', padding: 5, }}>Floor mats</Text>
                </View>
                <View style={{  flexDirection: 'row',  }}>
                  <Text style={{top: FS*10, fontSize: FS* 5}}>⬤</Text>
                  <Text style={{fontSize: FS* bodyTextSize,  color: '#2a3614', padding: 5, }}>Floors</Text>
                </View>
                <View style={{  flexDirection: 'row',  }}>
                  <Text style={{top: FS*10, fontSize: FS* 5}}>⬤</Text>
                  <Text style={{fontSize: FS* bodyTextSize,  color: '#2a3614', padding: 5, }}>Seats</Text>
                </View>
                </View>

              </View>
            </View>
          </View>
          
          }

          <TouchableOpacity style={{ backgroundColor: '#619196', borderRadius: 10, justifyContent: 'center', alignItems: 'center', alignSelf: 'center', bottom: props.double ? FS*25 : FS*25, padding: 10 }} 
              onPress={() => switchTabs(1, 'ContactScreen')}>
            <Text>Schedule now / Ask Questions !</Text>
          </TouchableOpacity>
        </View>
      
    </View>
    )
}


const makeStyles = fontScale => StyleSheet.create({
  title: {
    fontFamily: 'CG-Times Bold', 
    fontSize: 100 * fontScale, 
    color: '#F60081', 
  },
  name: {
    fontFamily: 'CG-Times Bold', 
    fontSize: 30 * fontScale,  
    color: 'white',
    padding: 5,
  },
  button: {
    paddingRight: 20,
    paddingLeft: 20,
    padding: 15,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#eafbf2',
    borderRadius: 30,
    top: 25
  },
  buttonSong: {
    width: 375,
    height: 50,
    padding: 10,
    alignItems: 'center',
    backgroundColor: 'white',
    borderRadius: 30,
    flexDirection: 'row',
    marginTop: 20,
    alignSelf :'center'
  },
  buttonText: {
    fontSize: 15,
    fontFamily: 'CG-Times Bold', 
  },
  buttonSongText: {
    fontSize: 20,
    fontFamily: 'CG-Times Bold', 
    marginLeft: 15
  },
  email: {
    color: 'white', 
    fontFamily: 'CG-Times Bold', 
    position: 'absolute', 
    bottom: 25, 
    fontSize: 17
  }
});
