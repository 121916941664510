import { StyleSheet, Text, View, useWindowDimensions, Dimensions, Linking, Image, Animated, Easing} from 'react-native';
import { useFonts } from 'expo-font';
import { useEffect, useState, useRef, useCallback, useContext } from 'react';
import { TouchableOpacity } from 'react-native-web';
import { useNavigation } from '@react-navigation/native';
import { MainContext } from '../Contexts/MainContext';

const { height, width } = Dimensions.get('window');
// const fontScale = c

export default function WhatWeDoCard(props) {
  const {tabIndex, setTabIndex} = useContext(MainContext)
  const [FS, setFS] = useState(Math.min(window.innerWidth / 320, window.innerHeight / 640))
  const [FSS, setFSS] =  useState({ "width": Math.min(window.innerWidth), "height": Math.min(window.innerHeight)})
  const styles = makeStyles(FS); // pass in fontScale to the StyleSheet
  const smallScreen = FSS.width < 900;
  const navigation = useNavigation();

  useEffect(() => {
    window.addEventListener('resize', changeTitleSize);
    return () => {
        window.removeEventListener('resize', changeTitleSize);
    }
  }, []);

  function changeTitleSize(){
    setFS(Math.min(window.innerWidth / 320, window.innerHeight / 640))
    setFSS({ "width": Math.min(window.innerWidth), "height": Math.min(window.innerHeight)})
  }

  function switchTabs(index, screen){
    setTabIndex(index)
    props.switchTabs(screen)
}

  return (
    <View style={{ alignItems: 'center', height: FS * 565, width: FS * 300, backgroundColor: '#f1ead7', borderRadius: 20}}>
      <View style={{padding: 10}}>
        <Image
          source={props.image}
          style={{height: FS*250, width: FS*250, borderRadius: 1000}}
        />
      </View>
      <Text style={{fontSize: FS * 30, fontWeight: 'bold', color: '#2a3614', textAlign: 'center'}}>{props.title}</Text>
      <Text style={{fontSize: FS* 17,  color: '#2a3614', padding: 10, textAlign: 'center'}}>{props.body}</Text>
      <TouchableOpacity style={{position: 'absolute', bottom: FS*20, borderBottomColor: '#2a3614', borderBottomWidth: 1,  }}
        onPress={() => switchTabs(1, 'ServicesScreen')}>
        <Text style={{fontSize: FS*15}}>Read More</Text>
      </TouchableOpacity>
    </View>
  );
}


const makeStyles = fontScale => StyleSheet.create({
  title: {
    fontFamily: 'CG-Times Bold', 
    fontSize: 100 * fontScale, 
    color: '#F60081', 
  },
  name: {
    fontFamily: 'CG-Times Bold', 
    fontSize: 30 * fontScale,  
    color: 'white',
    padding: 5,
  },
  button: {
    paddingRight: 20,
    paddingLeft: 20,
    padding: 15,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#eafbf2',
    borderRadius: 30,
    top: 25
  },
  buttonSong: {
    width: 375,
    height: 50,
    padding: 10,
    alignItems: 'center',
    backgroundColor: 'white',
    borderRadius: 30,
    flexDirection: 'row',
    marginTop: 20,
    alignSelf :'center'
  },
  buttonText: {
    fontSize: 15,
    fontFamily: 'CG-Times Bold', 
  },
  buttonSongText: {
    fontSize: 20,
    fontFamily: 'CG-Times Bold', 
    marginLeft: 15
  },
  email: {
    color: 'white', 
    fontFamily: 'CG-Times Bold', 
    position: 'absolute', 
    bottom: 25, 
    fontSize: 17
  }
});
