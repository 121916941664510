import { StatusBar } from 'expo-status-bar';
import  { useState, useEffect, useRef, useContext } from 'react'
import { StyleSheet, Text, View, Dimensions, TouchableOpacity, Linking } from 'react-native';
import { MaterialCommunityIcons } from '@expo/vector-icons';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

export default function Instagram(props) {


  function toInstagram(){
    Linking.canOpenURL('https://www.instagram.com/detailsofgreensboro/').then(supported => {
      if (supported) {
        Linking.openURL('https://www.instagram.com/detailsofgreensboro/');
      } else {
        ////console.log("Don't know how to open URI: ");
      }
    });
  }

  return (
    <View style={{ height: 50, width: 50, borderRadius: 10, backgroundColor: 'transparent',}}>
      <TouchableOpacity onPress={() => toInstagram()}>
        <View>
           <MaterialCommunityIcons
            name="instagram"
            size={props.smallScreen ? 35 : 30}
            color='#2a3614'
          />
        </View>
      </TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    height: 50,
    width: 50,
    borderRadius: 10,
    backgroundColor: 'transparent',
    right: 0,
    top: 40
  },
});
