import * as React from "react"
import { StyleSheet, Text, View, } from 'react-native';
import Svg, { Path } from "react-native-svg"

const Wave = (props) => (
  <View  style={[styles.header,
    {
      transform: [{rotate: '180deg'}],
      // backgroundColor: 'red',
    },
  ]}>
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" preserveAspectRatio="none"  {...props}>
    <Path
      fill="#f1ead7"
      d="m0 128 80-10.7C160 107 320 85 480 96c160 11 320 53 480 64s320-11 400-21.3l80-10.7v192H0Z"
    />
  </Svg>
  </View>
)
export default Wave

const styles = StyleSheet.create({
header:{
}
});

